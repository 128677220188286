'use client';
import Container from '@/shared/ui/Container/ui/Container';
import { LazyImage } from '@/shared/ui/LazyImage/LazyImage';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import { FC, Suspense, useState } from 'react';
import { LocalizedLink } from '@/shared/i18n/routing';
import { useGetFooterOptions } from '@/shared/hooks/useGetFooterOptions';
import { useLocale } from 'next-intl';
import { LocaleSwitcher } from '@/features/LocaleSwitcher/LocaleSwitcher';
import { useGetFooterMenu } from '@/shared/hooks/useGetFooterMenu';
import { useGetOurProjectsMenu } from '@/shared/hooks/useGetOurProjectsMenu';
import DefaultPopover from '@/entities/DefaultPopover/DefaultPopover';
import { CloseIcon } from 'next/dist/client/components/react-dev-overlay/internal/icons/CloseIcon';

interface FooterProps {
  className?: string;
}

const Footer: FC<FooterProps> = ({ className }) => {
  const locale = useLocale();

  const { data: footerOptions } = useGetFooterOptions({ lang: locale });

  const { data: footerMenu } = useGetFooterMenu({ lang: locale });

  const { data: ourProjectsMenu } = useGetOurProjectsMenu({ lang: locale });

  const [isProjectsOpen, setIsProjectsOpen] = useState(false);

  return (
    <footer className={clsx(cvaFooter(), className)}>
      <Container size={'xl'} gutters={'xl'}>
        <div className={cvaFooterInner()}>
          <div className={cvaFooterWrapper()}>
            <p className={cvaFooterText()}>
              {footerOptions?.settings_copyright}
            </p>
            {footerMenu?.items.map((i) => (
              <LocalizedLink
                key={i.ID}
                className={cvaFooterText()}
                href={i.url}>
                {i.title}
              </LocalizedLink>
            ))}
          </div>
          <div className={clsx(cvaFooterWrapper(), 'md-max:hidden')}>
            <p className={cvaFooterTextBody()}>
              {footerOptions?.settings_our_projects.text + ':'}
            </p>
            {ourProjectsMenu?.items.map((i) => (
              <LocalizedLink
                key={i.ID}
                href={i.url}
                className={clsx(cvaFooterText())}>
                <LazyImage
                  imageWrapClass={'relative w-4 h-4'}
                  className={'object-contain'}
                  src={i.acf.icon.url}
                  alt={i.acf.icon.alt ?? ''}
                  quality={100}
                  fill
                />
                {i.title}
              </LocalizedLink>
            ))}
          </div>

          <div className={cvaFooterWrapper()}>
            <Suspense>
              <LocaleSwitcher />
            </Suspense>
            {footerOptions?.settings_our_projects.url ? (
              <div className={'hidden md-max:block'}>
                <DefaultPopover
                  className={'xs-max:w-full xs-max:px-1.6'}
                  placement={'top-end'}
                  render={
                    <div
                      className={clsx(
                        cvaFooterWrapper(),
                        'flex-col bottom-0 relative'
                      )}>
                      <div
                        onClick={() => {
                          setIsProjectsOpen(false);
                        }}
                        className={'top-0 right-0 absolute'}>
                        <CloseIcon />
                      </div>
                      <p className={cvaFooterTextBody()}>
                        {footerOptions?.settings_our_projects.text + ':'}
                      </p>
                      {ourProjectsMenu?.items.map((i) => (
                        <LocalizedLink
                          key={i.ID}
                          href={i.url}
                          className={clsx(cvaFooterText())}>
                          <LazyImage
                            imageWrapClass={'relative w-4 h-4'}
                            className={'object-contain'}
                            src={i.acf.icon.url}
                            alt={i.acf.icon.alt ?? ''}
                            quality={100}
                            fill
                          />
                          {i.title}
                        </LocalizedLink>
                      ))}
                    </div>
                  }
                  isOpen={isProjectsOpen}>
                  {''}
                </DefaultPopover>
                <div
                  onClick={() => {
                    setIsProjectsOpen(true);
                  }}
                  className={clsx(cvaFooterText())}>
                  {footerOptions?.settings_our_projects.text}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </Container>
    </footer>
  );
};

const cvaFooter = cva(['Footer-cvaFooter', 'py-1.2 md-max:py-2 sm-max:py-0.8']);

const cvaFooterInner = cva([
  'Footer-cvaFooterInner',
  'grid grid-cols-3 justify-between items-center',
  'md-max:flex sm-max:flex-col-reverse',
  'sm-max:items-stretch sm-max:gap-0.4',
]);

const cvaFooterWrapper = cva([
  'Footer-cvaFooterWrapper',
  'flex items-center gap-1.6 sm-max:justify-between md-max:gap-1.2',
  'last:justify-end sm-max:last:justify-between ',
]);

const cvaFooterText = cva([
  'Footer-cvaFooterText',
  'text-cGray300 text-1.4-500',
  'flex items-center gap-0.8',
]);

const cvaFooterTextBody = cva([
  'Footer-cvaFooterTextBody',
  'text-cGray500 whitespace-nowrap text-1.4-400',
]);

export default Footer;
