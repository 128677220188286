import { useQuery } from '@tanstack/react-query';
import { getPhotoCategories } from '@/shared/api/photo/getPhotoCategories';
import { IPhotoCategory } from '@/shared/api/photo/getPhotoCategories/types';

type Params = Parameters<typeof getPhotoCategories>[0];

interface Options {
  type: 'photo' | 'contest' | 'all';
}

export const useGetPhotoCategories = (params: Params, options: Options) => {
  return useQuery({
    queryKey: getUseGetPhotoCategoriesQueryKey(params),
    queryFn: () => getPhotoCategories(params),
    staleTime: 3600 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    select: (data) => {
      switch (options.type) {
        case 'photo':
          return data.filter(isPhotoCategory);
        case 'contest':
          return data.filter(isActiveContestCategory);
        case 'all':
          return data.filter((i) => {
            return isPhotoCategory(i) || isActiveContestCategory(i);
          });
      }
    },
  });
};

export const getUseGetPhotoCategoriesQueryKey = (params: Params) => [
  'getPhotoCategories',
  params,
];

const isPhotoCategory = (photoCategory: IPhotoCategory) =>
  Boolean(!photoCategory.contest || Array.isArray(photoCategory.contest));

const isActiveContestCategory = (photoCategory: IPhotoCategory) =>
  Boolean(
    photoCategory.contest &&
      !Array.isArray(photoCategory.contest) &&
      Object.keys(photoCategory.contest).length > 0 &&
      photoCategory.contest.is_active
  );
