import { QueryClient, useQuery } from '@tanstack/react-query';
import { getHeaderMenu } from '@/shared/api/menus/getHeaderMenu';

type Params = Parameters<typeof getHeaderMenu>[0];

const QUERY_KEY = 'getHeaderMenu' as const;

export const useGetHeaderMenu = (params: Params) => {
  const queryKey = [QUERY_KEY, params.lang] as const;

  return useQuery({
    queryKey,
    queryFn: () => getHeaderMenu(params),
    staleTime: Infinity,
    gcTime: 24 * 60 * 60 * 1000,
  });
};

export const prefetchGetHeaderMenu = async (
  queryClient: QueryClient,
  params: Params
) => {
  const queryKey = [QUERY_KEY, params.lang] as const;
  const cachedData = queryClient.getQueryData(queryKey);

  if (cachedData) {
    return;
  }

  await queryClient.prefetchQuery({
    queryKey,
    queryFn: () => getHeaderMenu(params),
    staleTime: Infinity,
  });
};

export const getUseGetHeaderMenuQueryKey = (params: Params) => [
  'getHeaderMenu',
  params,
];
